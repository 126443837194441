<template>
  <div class="bg-cover bg-no-repeat relative w-full h-full"
      :style="{backgroundImage: `url('${mobileBg}')`}">
    <!-- <div class="w-full absolute bottom-24"
        style="height: calc(100vh - 6rem);"> -->
    <div class="w-full absolute bottom-24"
        :style="{height: menuHeight}">
      <div class="w-full h-1/3 flex items-end">
        <img :src="logo" alt="paper crane logo" class="mb-14 w-32 mx-auto">
      </div>
      <!-- <div class="mobile-menu grid grid-cols-1 gap-16 text-center absolute bottom-96 w-full"> -->
      <div class="mobile-menu grid grid-cols-1 text-center w-full h-2/3 text-pc-grey-dark">

        <!-- <router-link to="/">
          <div @click="changeRoute('home')" class="font-mont text-2xl" :class="{'text-black': (currentRoute == 'home')}">
            home.
          </div>
        </router-link> -->
        <template v-for="(item, index) in menuItems">
          <div class="w-16 h-0.5 bg-pc-grey justify-self-center"></div>
          <router-link :to="'/' + item">
            <div @click="changeRoute(item)"
              class="font-mont text-2xl"
              :class="{'text-black': (currentRoute == item)}">
              {{item}}.
            </div>
          </router-link>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
  import mobileBg from 'images/mobile_menu_bg.png';
  import logo from 'images/logo.png';
  import { NavEventBus } from '../../helpers/nav-event-bus.js';
  export default {
    name: 'HamMenu',
    props: {

    },
    data: function() {
      return {
        menuHeight: `${window.innerHeight - 96}px`,
        currentRoute: 'home',
        mobileBg, logo,
        menuItems: ['about', 'services', 'work', 'community', 'connect']
      }
    },
    mounted() {
      this.currentRoute = this.$route.name
      NavEventBus.$on('changeRoute', this.setRoute)
    },
    methods: {
      setRoute(route) {
        this.currentRoute = route
      },
      changeRoute(route) {
        // console.log('from ham-menu, route:', route)
        this.$emit('route', route)
        this.currentRoute = route
        NavEventBus.$emit('hamOpen')
      }
    }
  }
</script>

<style scoped>
</style>
