import Vue from 'vue/dist/vue.esm'
import App from '../app.vue'
import TurbolinksAdapter from 'vue-turbolinks'; Vue.use(TurbolinksAdapter)
import VueRouter from 'vue-router'; Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history' ,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

import VueSweetalert2 from 'vue-sweetalert2'; Vue.use(VueSweetalert2);
import axios from 'axios';

// SVG
import { index } from '../assets/svg'
index.forEach(svg => Vue.component(svg.name, svg.component))

// GLOBAL COMPONENTS
import NavbarLanding from '../views/components/navbar-landing'; Vue.component('navbar-landing', NavbarLanding)

// const host = process.env.RAILS_ENV == 'development' ? 'http://localhost:3000/api/v1/' : 'https://papercranetech.co/api/v1/'

const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

Vue.use({
  install (Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: '/api/v1/',
      headers: {"X-CSRF-TOKEN": csrf}
    })
  }
})

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    router: router,
    el: '#hello',
    data: {
    },
    components: { App }
  })
})
