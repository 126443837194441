<template>
  <div id="nav-icon3" class="w-8 lg:w-12 h-6 lg:h-10" :class="{ open: isOpen }" @click="open">
    <span class="w-3/4 top-0"></span>
    <span class="w-full top-3"></span>
    <span class="w-full top-3"></span>
    <span class="w-3/4 top-6"></span>
  </div>
</template>

<script>
  import { NavEventBus } from '../../helpers/nav-event-bus.js';

  export default {
    name: 'HamIcon',
    data: function() {
      return {
        isOpen: false
      }
    },
    mounted() {
      NavEventBus.$on('hamOpen', () => this.open() )
      NavEventBus.$on('hamClose', () => this.isOpen = false )
      // console.log('ham icon isOpen mounted', this.isOpen)
    },
    methods: {
      open() {
        this.isOpen = !this.isOpen
        // console.log('ham icon isOpen open method', this.isOpen)
      }
    }
  }
</script>

<style scoped>
#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  position: relative;
  margin: auto auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 0.225rem;
  /* width: 100%;*/
  background: #333333;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/*#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 15px;
}

#nav-icon3 span:nth-child(4) {
  top: 30px;
}*/

#nav-icon3.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
</style>
