<template>
  <div>
    <div class="lg:hidden cursor-pointer" @click="toggleHamMenu">
      <!-- <svg-hamburger /> -->
      <ham-icon />
    </div>

    <div class="hidden lg:inline-flex">
      <!-- <template v-for="(item, index) in menuItems">
        <router-link :to="item"><div @click="changeRoute(item)" v-if="index != menuItems.length - 1" class="font-mont text-lg pr-8 mr-8 border-r border-gray-300 h-3/4">{{item}}.</div></router-link>
      </template> -->
      <router-link to="/about"><div @click="changeRoute('about')" class="font-mont text-lg text-black pr-8 mr-8 border-r border-gray-300 h-3/4 hover:font-bold">about.</div></router-link>

      <router-link to="/services"><div @click="changeRoute('services')" class="font-mont text-lg text-black pr-8 mr-8 border-r border-gray-300 h-3/4 hover:font-bold">services.</div></router-link>

      <router-link to="/portfolio"><div @click="changeRoute('portfolio')" class="font-mont text-lg text-black pr-8 mr-8 border-r border-gray-300 h-3/4 hover:font-bold">work.</div></router-link>

      <router-link to="/workshops"><div @click="changeRoute('workshops')" class="font-mont text-lg text-black pr-8 mr-8 border-r border-gray-300 h-3/4 hover:font-bold">community.</div></router-link>

    </div>
  </div>

</template>

<script>
  import { NavEventBus } from '../../helpers/nav-event-bus.js';
  import HamIcon from './ham-icon';

  export default {
    name: 'LandingMenu',
    props: {
      showHamMenu: { type: Boolean, default: false },
    },
    data: function() {
      return {

        menuItems: ['about', 'services', 'portfolio', 'workshops', 'connect']
      }
    },
    components: { HamIcon },
    methods: {
      toggleHamMenu() {
        this.$emit('showHamMenu', !this.showHamMenu)
      },
      changeRoute(route) {
        console.log('from landing-menu, route:', route)
        this.$emit('route', route)
        // NavEventBus.$emit('changeRoute', route)
      }
    }
  }
</script>

<style scoped>
  .main {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
</style>
