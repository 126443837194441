<template>
  <div class="font-mont text-base">
    <p class="text-pc-grey-dark font-semibold">
      {{item.h2}}
    </p>

    <div class="mt-10">
      <div class="grid grid-cols-1 gap-3">
        <p v-for="serviceItem in item.items" v-bind:key="serviceItem">{{serviceItem}}</p>

        <router-link :to="'/' + item.path">
          <button @click="changeRoute(item.path)" class="mt-4 font-semibold flex items-center space-x-3" :class="item.color">
            <span>{{item.pathTitle}}</span>
            <img :src="chevrons[item.color]" class="w-2" alt="">
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import chevronPri from 'images/chevron_pri.svg'
  import chevronSec from 'images/chevron_sec.svg'
  import chevronTer from 'images/chevron_ter.svg'

  import { NavEventBus } from '../../helpers/nav-event-bus.js';

  export default {
    name: 'ServiceItem',
    props: {
      item: { type: Object, value: {}},
    },
    methods: {
      changeRoute(route) {
        console.log('changeroute in service-item :', route)
        NavEventBus.$emit('changeRoute', route)
      }
    },
    data() {
      return {
        chevrons: {
          'text-ter': chevronTer,
          'text-pri': chevronPri,
          'text-sec': chevronSec
        }
      }
    }
  }

</script>
