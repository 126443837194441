<template>
  <!-- <div class="pt-32 lg:px-32 box-border w-full bg-no-repeat bg-cover bg-fixed relative from-pri via-sec to-ter bg-services-pattern"> -->
  <div class="pt-18 lg:pt-32 px-8 lg:px-32 box-border w-full bg-no-repeat bg-cover bg-fixed relative from-transparent via-white to-white lg:to-transparent bg-services-pattern">
    <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/pc_service_title.png" class="w-full h-60 lg:h-96 object-cover rounded-xl mt-4">

    <!-- content -->
    <div class="pt-8 lg:py-10 lg:px-60 pb-32">
      <h2 class="font-pc text-3xl lg:text-4xl text-center"><span class="text-sec">what</span> we do.</h2>

      <div class="mt-8 lg:mt-10 font-mont text-base">
        <p>
          We empower businesses to create dynamic and engaging digital experiences that deliver real value to their customers. With our expertise in both local and international markets, we guide brands and companies through the complexities of today's digital landscape. Whether building a stronger local presence or reaching new audiences internationally, our team is here to help bridge markets and drive impactful connections across borders.
        </p>

        <div class="grid grid-cols-1 gap-3">
          <template v-for="(item, index) in services">
            <div v-bind:key="item.h1" @click="expand" class="mt-10 flex items-center justify-between py-2 cursor-pointer"
              :class="expandedIndex != index ? expandedClasses[index][0] : expandedClasses[index][1]"
              :data-index="index">
              <p class="text-4xl lg:text-5xl font-semibold">{{item.h1}}</p>
              <img :src="expandedIndex != index ? chevronBlack : expandedClasses[index][2]"
                class="w-3 lg:w-4 transition duration-400"
                :class="expandedIndex == index ? 'rotate-90' : ''">
            </div>
            <template v-if="expandedIndex == index">
              <service-item :item="item" />
            </template>

          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ServiceItem from '../components/service-item.vue'
  import chevronBlack from 'images/chevron_black.svg'
  import chevronPri from 'images/chevron_pri.svg'
  import chevronSec from 'images/chevron_sec.svg'
  import chevronTer from 'images/chevron_ter.svg'
  export default {
    name: 'Services',
    props: {

    },
    components: {ServiceItem},
    data() {
      return {
        chevronBlack,
        // services: ['digital transformation', 'consultation', 'workshops'],
        services: [
          { color: 'text-pri', path: 'work', pathTitle: 'See our work', h1: 'digital transformation', h2: "Businesses that realign and develop engaging digital experiences better serve and deliver value to customers.", items: ['Wechat Mini Program', 'Web Design & Development', 'Product Design & Prototype', 'E-Commerce Solutions', 'And More...'], image: "https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/digital-transformation.png" },
          { color: 'text-sec', path: 'work', pathTitle: 'See our work', h1: 'consultation', h2: "You know your business best. Let us help you by implementing what we know best to help you grow and scale.", items: ['Data Consultancy & BI', 'Digital Marketing', 'Branding & Growth', 'Brand Identity Systems', 'And More...'], image: "https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/consultation.png" },
          { color: 'text-ter', path: 'community', pathTitle: 'See our work', h1: 'workshops', h2: "Communities that receive continued support and valuable tech training collaborate to build value driven products.", items: ['Corporate Training', 'Community Workshops', 'Events', 'Panel Discussions', 'And More...'], image: "https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/workshops1.png" },
        ],
        expandedIndex: null,
        expandedClasses: [
          ['border-b-1.5', 'text-pri', chevronPri],
          ['border-b-1.5', 'text-sec', chevronSec],
          ['border-b-1.5', 'text-ter', chevronTer],
        ]
      }
    },
    mounted() {
      console.log('services mounted route,', this.$route.name)
    },
    methods: {
      expand(e) {
        // console.log(e.currentTarget.dataset)
        const index = parseInt(e.currentTarget.dataset.index)
        if (this.expandedIndex == index) {
          this.expandedIndex = null
        } else {
          this.expandedIndex = index
        }
      }
    }
  }
</script>
