<template>
  <div class="bg-repeat-y bg-cover w-full" style="background-image: url('https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/about-bg.png')">

    <div class="pt-28 px-8 lg:px-40 lg:pt-32 flex flex-col-reverse lg:flex-row">

      <div class="mt-6 lg:mt-24 lg:flex justify-between lg:flex-col lg:w-2/3 w-full">
        <h1 class="mb-8 font-pc text-pc-grey-black font-bold text-center lg:text-left text-7xl lg:text-7xl">hello.</h1>
        <p class="px-3 lg:px-0 font-mont text-xl lg:text-lg text-pc-grey-black lg:w-3/4">we are paper crane tech, your trusted data and tech solutions partner. We build value based relationships and craft data and digital solutions to help solve business, social and community needs.</p>

        <div class="flex flex-row justify-around lg:justify-start lg:space-x-20 w-full mt-10 lg:mt-24 mb-20">
          <template v-for="item in [['work', 'our work'], ['connect', 'let\'s talk']]">
            <router-link :to="'/' + item[0]">
              <button @click="changeRoute(item[0])" class="font-mont flex items-center text-lg lg:text-sm font-bold lg:bold-text text-ter">{{item[1]}} <img :src="chevron" class="ml-3 h-2.5"></button>
            </router-link>
          </template>
        </div>
      </div>

      <div class="mt-10 lg:mt-150 flex-shrink-0 w-full lg:w-1/3 justify-center flex lg:pr-20">
        <div>
          <svg-logo class="lg:h-60 h-40" />
          <svg-shadow class="w-28 lg:w-36 ml-16 mt-6 lg:mt-10" />
        </div>
      </div>
    </div>

    <div class="mt-0 mb-4 border-b-2 border-pc-grey mx-8 lg:mx-40"></div>
    <p class="px-8 lg:px-40 font-mont font-extrabold text-base lg:text-sm text-pri">leadership</p>
    <p class="px-8 lg:px-40 mt-2 font-mont text-lg lg:text-lg text-pc-grey-black font-semibold">Founded by 3 female entrepreneurs, we are a collective of planners, strategists, designers and developers; and we’ll work together with you as your tech partners to build and bring your innovative digital products to life.</p>
    <p class="px-8 lg:px-40 mt-8 font-mont text-lg lg:text-lg text-pc-grey-black font-semibold">Get to know us!</p>

    <div class="flex flex-col lg:flex-row lg:space-x-16 my-4 lg:my-20 lg:justify-between lg:px-40 p-8">
      <div class="flex flex-col mb-12 lg:my-0">
       <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/aggy.png" alt="aggy" class="">
       <p class="text-base lg:text-xs font-mont font-semibold">product & data engineering. business intelligence.</p>
       <p class="text-sec font-mont text-sm lg:text-xs">liverpool fc 4 life. chilli sauce lover. pikachu sidekiq.</p>
      </div>
      <div class="flex flex-col mb-12 lg:my-0">
      <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/lin.png" alt="lin" class="">
       <p class="text-base lg:text-xs font-mont font-semibold">operations. digital marketing. product growth.</p>
       <p class="text-pri font-mont text-sm lg:text-xs">LGBTQ+ advocate. 80’s music lover. avid foodie.</p>
     </div>
     <div class="flex flex-col mb-12 lg:my-0">
      <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/ivy.png" alt="ivy" class="">
       <p class="text-base lg:text-xs font-mont font-semibold">branding. market strategy. ui/ux design.</p>
       <p class="text-ter font-mont text-sm lg:text-xs">lowkey cat lady. vinyl enthusiast. street photographer.</p>
     </div>
    </div>

    <div class="mt-0 mb-4 border-b-2 border-pc-grey mx-8 lg:mx-40"></div>

    <p class="px-8 lg:px-40 font-mont font-extrabold text-base lg:text-sm text-sec">values</p>
    <p class="px-8 lg:px-40 mt-2 lg:mb-10 font-mont text-lg lg:text-lg text-pc-grey-black font-semibold">We work with companies and brands that value collaboration and long term partnership as much as we do, building products that create a positive impact on the world.</p>

    <div class="flex flex-col items-center lg:flex-row lg:justify-between px-8 lg:px-32">
      <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/values-1.png" alt="" class="py-12 w-full lg:w-1/2 lg:px-24 lg:py-8">
      <p class="font-mont text-lg lg:text-lg lg:px-24 lg:py-8">We tailor our services specifically to each of our client’s needs; using the art of digital transformation and design thinking to craft unique, bespoke solutions that meet business, social, and community needs across the globe.</p>
    </div>
    <div class="flex flex-col-reverse lg:px-32 items-center lg:flex-row lg:justify-between px-8 lg:pb-20">
      <div class="lg:px-24 lg:py-8">
        <p class="font-mont text-lg lg:text-lg">We are passionate about collaboration and community, and by working closely with our valued partners, we strive to bring together the next generation of value driven leaders and consumers within the tech space.</p>
        <router-link to="/connect">
          <button @click="changeRoute('connect')" class="w-full flex items-center mb-20 lg:mb-4 font-mont font-extrabold text-lg text-center lg:text-left lg:text-sm mt-8 text-ter">connect with us <img :src="chevron" class="ml-3 h-2.5"></button>
        </router-link>
        </div>
        <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/values-2.png" alt="" class="py-12 w-full lg:w-1/2 lg:px-24 lg:py-8">
      </div>
  </div>
</template>


<script>
import { NavEventBus } from '../../helpers/nav-event-bus.js';
import chevron from 'images/chevron_ter.svg'
export default {
  name: 'About',
  props: {
  },
  data: function() {
    return {
      chevron
    }
  },
  mounted() {
    console.log('about mounted')
  },
  methods: {
    changeRoute(route) {
      console.log({route})
      NavEventBus.$emit('changeRoute', route)
    }
  },
  created() {
    // document.body.style.backgroundColor = '#FFF'
  }
}
</script>
<style scoped>

.bold-text {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
