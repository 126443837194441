const SvgLogo = () => import('./logo.vue')
const SvgNavLogo = () => import('./nav-logo.vue')
const SvgLandingBg = () => import('./landing-bg.vue')
const SvgHamburger = () => import('./hamburger.vue')
const SvgShadow = () => import('./shadow.vue')
const SvgDigitalTransformation = () => import('./digital-transformation.vue')
const SvgConsultation = () => import('./consultation.vue')
const SvgDesignThinking = () => import('./design-thinking.vue')
const SvgCommunity = () => import('./community.vue')
const SvgServicesBg = () => import('./services-bg.vue')
const SvgClose = () => import('./close.vue')

const index = [
    {name: 'svg-logo', component: SvgLogo},
    {name: 'svg-nav-logo', component: SvgNavLogo},
    {name: 'svg-landing-bg', component: SvgLandingBg},
    {name: 'svg-hamburger', component: SvgHamburger},
    {name: 'svg-shadow', component: SvgShadow},
    {name: 'svg-digital-transformation', component: SvgDigitalTransformation},
    {name: 'svg-consultation', component: SvgConsultation},
    {name: 'svg-design-thinking', component: SvgDesignThinking},
    {name: 'svg-community', component: SvgCommunity},
    {name: 'svg-services-bg', component: SvgServicesBg},
    {name: 'svg-close', component: SvgClose},
  ]

export {
  index
}
