<template>
  <div class="parent h-60 lg:h-96 w-full rounded-xl relative cursor-pointer overflow-hidden">

    <div class="child bg-center bg-cover absolute inset-0 flex flex-col justify-center items-center text-white text-center px-4"
      :style="{backgroundImage: `linear-gradient(0deg, rgba(51, 51, 51, 0.50), rgba(51, 51, 51, 0.25)), url('${image}')`}">
        <h3 class="uppercase font-bold text-lg">{{title}}</h3>
        <h5 class="lowercase text-sm">{{description}}</h5>
      </div>
      <div class="absolute inset-2 border-2 border-white rounded-lg">
    </div>

  </div>
</template>

<script>
  export default {
    props: {
      image: String, title: String, description: String
    }
  }
</script>

<style scoped>
  .parent:hover > .child {
    transition: all .5s;
    transform: scale(1.5);
  }
</style>
