<template>
  <!-- <div class="pt-24 lg:pt-32 font-mont px-8 lg:px-48 w-full pb-24"> -->
  <div class="bg-white px-8 lg:px-32 py-16 lg:py-16 relative overflow-scroll w-full h-full">

    <div :style="{opacity: showX ? 1 : 0}"
      @click="closePopup"
      class="fixed cursor-pointer bg-white shadow-lg lg:shadow-none box-border p-2 lg:p-0 rounded-full top-7 right-5 lg:top-18 lg:right-30 w-12 h-12 transition duration-300"
      style="z-index: 101;">
      <img :src="close" alt="close button" class="opacity-50">
    </div>
    <div class="w-full rounded-2xl bg-cover bg-center relative h-56 lg:h-34rem"
          style="z-index: 1;"
          :style="{backgroundImage: `url(${workshop.cover_image})`}">
      <div class="absolute inset-0 bg-white bg-opacity-60" style="z-index: -1;"></div>
      <div class="absolute inset-2 lg:inset-4 border lg:border-3 border-white rounded-xl"></div>
      <div class="w-full h-full flex flex-col justify-center items-center z-10 px-3">
        <h3 class="text-black text-3xl lg:text-7xl font-pc text-center">{{workshop.title}}</h3>
        <h5 class="text-pri text-base lg:text-2xl font-semibold text-center mt-2 lg:mt-4">{{workshop.description}}</h5>
      </div>
    </div>

    <div class="lg:px-44 mt-8 lg:mt-16">
      <p class="text-ter font-semibold lg:text-base">introduction</p>
      <p class="mt-2">{{workshop.introduction}}</p>

      <!-- <p class="text-ter font-semibold lg:text-base mt-8 lg:mt-16">product</p>
      <p class="mt-2">{{project.solution}}</p> -->

    </div>

    <!-- TEST -->
    <div v-show="workshop.images && workshop.images.length" class="mt-8 lg:mt-16 w-full lg:px-40">
      <div id="slider" class="swiper swiper-container h-64 lg:h-80 p-2 lg:p-4">
         <div class="swiper-wrapper">
            <div v-for="(item) in workshop.images"
              class="swiper-slide rounded-xl shadow-xl h-52 lg:h-64 bg-cover bg-center"
              :style="{backgroundImage: `url(${item})`}">
            </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>

    </div>

    <div class="lg:px-44 mt-8 lg:mt-16">
      <p class="text-sec font-semibold lg:text-base">workshop</p>
      <p class="mt-2">{{workshop.workshop}}</p>

    </div>

  </div>
</template>

<script>
  import Swiper from 'swiper';
  import { Navigation, Pagination } from 'swiper'
  Swiper.use([Navigation, Pagination]);
  // import Swiper styles
  // import 'swiper/css'

  import close from 'images/close.svg'

  export default {
    name: 'CommunityShow',
    props: {
      slug: String
    },
    data() {
      return {
        close,
        showX: false,
        workshop: {},
        swiper: null,
        swiperOption: {
          // centeredSlides: true,
          // slidesPerView: 2,
          // spaceBetween: 30,
          grabCursor: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          breakpoints: {
            360: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          },
        }
      }
    },
    components: {
      // Swiper,
      // SwiperSlide
    },
    mounted() {
      console.log('in community-show', this.$route)
      // const slug = this.$route.params.community_slug
      const slug = this.slug
      this.$api.get(`/workshops/${slug}`).then(res => {
        console.log({res})
        this.workshop = res.data.workshop
        this.swiper = new Swiper('.swiper-container', this.swiperOption)
        // console.log('swiper', this.swiper)
        setTimeout(() => this.showX = true, 500)
      })
    },
    methods: {
      onSwiper (swiper) {
        console.log(swiper)
      },
      onSlideChange () {
        console.log('slide change')
      },
      closePopup(e) {
        console.log('close popup')
        this.$emit('close')
      }
    }
  }
</script>

<style scoped>

</style>
