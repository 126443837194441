<template>
  <div>
    <!-- <transition name="darken">
      <div v-if="overlay" class="fixed top-0 left-0 w-full h-screen bg-white opacity-75 z-30"></div>
    </transition> -->
    <!-- <div class="z-40 w-full p-10 lg:p-8 fixed top-0 bg-white"> -->
    <div class="z-40 inset-x-0 p-6 lg:py-8 lg:px-11 fixed top-0" :class="bgWhite">

      <div class="flex justify-between items-center">
        <router-link to="/">
          <div id="navbar-logo" class="transition-opacity duration-500 ease-out" :style="{ opacity: logoOpacity }" @click="goToHome">
            <img :src="logo" class="w-24 lg:w-32 object-contain">
          </div>
        </router-link>

        <div class="cursor-pointer">
          <!-- <svg-hamburger /> -->

          <div class="lg:flex space-x-14 hidden font-mont text-xl text-pc-grey-dark">
            <router-link v-for="(menuItem, index) in menuItems" v-bind:key="menuItem" :to="'/' + menuItem">
              <span @click="changeRoute(menuItem)" :class="{'text-black': (currentRoute == menuItem)}">
                {{menuItem}}.
              </span>
            </router-link>
          </div>

          <div
              class="lg:hidden flex fixed bottom-6 right-6 w-14 h-14 place-items-center box-border"
              style="border-radius: 20px; background-color: #F9F9F9; box-shadow: 0px 0px 17.3489px rgba(0, 0, 0, 0.11); z-index: 999;"
              @click="displayHamMenu">
            <ham-icon />
          </div>
        </div>
      </div>

      <!-- hamburger menu -->
      <!-- <transition name="zoom"> -->
      <transition name="down">
        <div v-if="showHamMenu"
          class="fixed inset-0"
          style="z-index: 990;">
          <ham-menu @route="changeRoute" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import LandingMenu from './landing-menu';
import HamMenu from './ham-menu';
import HamIcon from './ham-icon';
import logo from 'images/logo_full.png'
import { NavEventBus } from '../../helpers/nav-event-bus.js';

export default {
  name: 'NavbarLanding',
  data: function () {
    return {
      logo,
      menuItems: ['about', 'services', 'work', 'community', 'connect'],
      currentRoute: this.route,
      // isHome: false,
      showHamMenu: false,
      overlay: false,
      logoOpacity: 1.0,
      selectedIndex: null,
      bgWhite: ''
    }
  },
  props: {
    home: { type: Boolean, default: false },
    route: String
  },
  components: { LandingMenu, HamMenu, HamIcon },
  created() {
    // this.isHome = this.home
    NavEventBus.$on('changeRoute', this.setRoute)
    window.addEventListener('scroll', (e) => {
      if (window.scrollY >= (window.innerHeight)*0.05) {
        // this.logoOpacity = 0
        this.bgWhite = 'bg-off-white'
      } else {
        // this.logoOpacity = 1.0
        this.bgWhite = ''
      }
    })
  },
  mounted() {
    // console.log('router?', this.$route.name)
    // this.currentRoute = this.$route.name
  },
  computed: {
  },
  methods: {
    setRoute(route) {
      console.log('in navbar setting route', route)
      this.currentRoute = route
    },
    goToHome() {
      this.changeRoute('home')
      this.overlay = false
      this.showHamMenu = false
      NavEventBus.$emit('hamClose')
    },
    displayHamMenu() {
      this.showHamMenu = !this.showHamMenu
      this.overlay = !this.overlay
      // console.log('overlay', this.overlay)
    },
    toggleHamMenu(show) {
      // console.log('showHamMenu in navbar landing', show)
      this.showHamMenu = show
      this.overlay = !this.overlay
      // console.log('overlay', this.overlay)
    },
    changeRoute(route) {
      // console.log('inside changeRoute')
      // this.$emit('route', route)
      NavEventBus.$emit('changeRoute', route)
      this.currentRoute = route
      // this.isHome = route == 'home'
      this.showHamMenu = false
      // console.log('isHome?', this.isHome)
      // console.log('showHamMenu?', this.showHamMenu)
    },
  }
}
</script>

<style scoped>
  .ham-menu-position {
    top: 5rem; right: 2.5rem;
  }

</style>
