<template>
<!-- <div class="gradient-bg"> -->
  <!-- <div class="large-box lg:rounded-5xl fixed top-0 bottom-0 inset-x-0 lg:inset-16 bg-no-repeat bg-bottom bg-cover lg:bg-contain lg:bg-bottom" style="background-image: url('https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/homebg.png')"> -->
  <div class="large-box lg:rounded-5xl fixed top-0 bottom-0 inset-x-0 lg:inset-16 bg-no-repeat bg-bottom bg-cover lg:bg-contain lg:bg-bottom" style="background-image: url('')">
    <div class="fixed inset-x-0 lg:inset-x-16 bottom-0 lg:bottom-16 h-1/3 lg:h-full"
      style="z-index: -1;">
      <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/homebg.png" class="object-bottom object-cover lg:object-contain w-full h-full lg:rounded-b-5xl">
    </div>

    <div class="mt-5 lg:grid grid-cols-5 relative lg:px-40 items-center hidden z-50">
      <router-link to="/about"><div @click="changeRoute('about')" class="font-mont text-lg text-black h-3/4">about.</div></router-link>

      <router-link to="/services"><div @click="changeRoute('services')" class="font-mont text-lg text-black h-3/4">services.</div></router-link>

      <div class="flex justify-center">
        <img :src="logo_full" alt="" class="w-40 mx-2">

      </div>

      <router-link to="/work"><div @click="changeRoute('work')" class="font-mont text-lg text-black h-3/4 text-right mr-10">work.</div></router-link>

      <router-link to="/community"><div @click="changeRoute('community')" class="font-mont text-lg text-black h-3/4 text-right">community.</div></router-link>
    </div>


    <!-- <div class="mx-20 lg:mx-0 w-10/12 mt-40 lg:mt-10 p-2 lg:p-32 lg:box-content lg:fixed lg:top-0 lg:h-screen lg:flex flex-col lg:flex-row justify-center lg:px-20 lg:px-40" > -->
    <div class="mt-28 sm:mt-36 md:mt-60 lg:mt-10 py-2 lg:py-32 lg:box-border lg:absolute lg:top-0 lg:bottom-0 lg:left-0 lg:right-0 w-5/6 lg:w-2/3 mx-auto">
      <div class="grid grid-cols-1 gap-4 md:gap-4 lg:gap-2 pt-5 text-center lg:text-left text-4xl sm:text-4xl md:text-6xl lg:text-7xl font-mont text-pc-grey-black">
        <p class="leading-none text-center lg:mb-0 lg:flex items-center">we<span class="mx-2 text-pri font-black">build</span><br class="block lg:hidden" />products.</p>
        <p class="leading-none text-center lg:mb-0 lg:flex items-center">we<span class="mx-2 text-sec font-extrabold">grow</span><br class="block lg:hidden" />brands. </p>
        <p class="leading-none text-center lg:mb-0 lg:flex items-center">we<span class="mx-2 text-ter font-extrabold">transform</span><br class="block lg:hidden" />communities. </p>
      </div>
    </div>
    <div class="w-full fixed inset-x-0 bottom-32 lg:bottom-36 flex items-center justify-center mt-20 lg:mt-48">
      <router-link to="/about">
        <button @click="changeRoute('about')" class="connect-btn rounded-2xl lg:rounded-xl lg:px-10 px-8 py-3 font-mont text-xl lg:text-sm text-white">learn more</button>
      </router-link>
    </div>

     <a href="http://beian.miit.gov.cn/" target="_blank" rel="nofollow" class="text-sm lg:text-base fixed bottom-5 left-5 lg:right-20 lg:text-right text-pc-grey-dark">沪ICP备2020029815号</a>
  </div>
<!-- </div> -->
</template>

<script>
import logo_full from 'images/logo_full.png'
import { NavEventBus } from '../../helpers/nav-event-bus.js';
// import homeBg from '../assets/background_images/homeBg.png'
export default {
  name: 'Home',
  props: {
  },
  data() {
    return {
      logo_full
    }
  },
  created() {
    // document.body.style.backgroundColor = '#EAEAEA'
  },
  methods: {
    changeRoute(route) {
      // console.log({route})
      NavEventBus.$emit('changeRoute', route)
    }
  }
}
</script>

<style scoped>

.large-box {
  background-color: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.11);
}

.connect-btn {
  /* width: 9.5em;
  border-radius: 15px;
  height: 3.5em; */
  background: #333333;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.grey-bg {
  background-color: #EAEAEA;
}

.gradient-bg {
  background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);
}

.scope-app {
  color: red;
}
</style>
