<template>
  <div class="pt-24 lg:pt-32 font-mont px-8 lg:px-36 bg-repeat-y bg-cover w-full pb-24"
      style="background-image: url('https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/about-bg.png')">

    <div v-show="showShow" class="fixed inset-0 pt-0 lg:pt-12 font-mont px-0 lg:px-24 w-full bg-black bg-opacity-60 overflow-hidden" style="z-index: 100;">
      <transition name="zoom">
        <template v-if="showShow">
          <community-show :slug="currentSlug" @close="closeShow" />
        </template>
      </transition>
    </div>

    <img src="https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/community_cover.png" alt="photo of community" class="w-full h-60 lg:h-130 rounded-xl object-cover">

    <div class="mt-8 lg:mt-10 lg:px-60 border-b-1.5 pb-16 lg:pb-24 border-pc-grey">
      <h2 class="text-center font-pc text-3xl lg:text-4xl"><span class="text-ter">why</span> we care about community</h2>
      <p class="mt-8 lg:mt-12">
        One of the fundamental pillars of our company lies within the importance of our community; encouraging others to continue challenging themselves to learn and grow through collaboration and mentorship.
      </p>
    </div>

    <div class="mt-2 border-b-1.5 pb-16 lg:pb-24 border-pc-grey">
      <p class="text-sec font-semibold">corporate training & workshops</p>

      <div class="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 lg:px-10">
        <!-- <router-link v-for="(item, index) in workshops.corporate" v-bind:key="index" :to="'/community/' + item.slug">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </router-link> -->
        <div v-for="(item, index) in workshops.corporate"
          v-bind:key="index"
          :to="'/community/' + item.slug"
          :data-slug="item.slug"
          @click="expandShow"
          class="cursor-pointer">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </div>
      </div>
    </div>

    <div class="mt-2">
      <p class="text-ter font-semibold">community workshops</p>

      <div class="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 lg:px-10">
        <!-- <router-link v-for="(item, index) in workshops.community" v-bind:key="index" :to="'/community/' + item.slug">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </router-link> -->
        <div v-for="(item, index) in workshops.community"
          v-bind:key="index"
          :to="'/community/' + item.slug"
          :data-slug="item.slug"
          @click="expandShow"
          class="cursor-pointer">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProjectCard from '../components/project-card'
  import CommunityShow from '../components/community-show'
  export default {
    name: 'Work',
    props: {},
    data() {
      return {
        workshops: [],
        showShow: false,
        currentSlug: null
      }
    },
    components: { ProjectCard, CommunityShow },
    mounted() {
      this.$api.get('workshops').then(res=> {
      console.log('api workshops', res.data.workshops)
      this.workshops = res.data.workshops
    })
    },
    methods: {
      expandShow(e) {
        console.log(e)
        this.currentSlug = e.currentTarget.dataset.slug
        this.showShow = true
      },
      closeShow(e) {
        this.showShow = false
        this.currentSlug = null
      }
    }
  }
</script>

