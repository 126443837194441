<template>
  <div id="app">
    <navbar-landing v-if="route !== 'home' || screenW < 1024" :home="home" :route="route" @route="changeRoute" />
    <!-- <p>{{ message }}</p> -->
    <transition name="component-fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>
import Home from './views/pages/home';
import About from './views/pages/about';
import Services from './views/pages/services';
import Work from './views/pages/work';
import WorkShow from './views/pages/work-show';
import Community from './views/pages/community';
import CommunityShow from './views/pages/community-show';
import Connect from './views/pages/connect';
import { NavEventBus } from './helpers/nav-event-bus.js';

const routes = [
    { path: '/', name: 'home', component: Home },
    { path: '/about', name: 'about', component: About },
    { path: '/services', name: 'services', component: Services },
    { path: '/work', name: 'work', component: Work },
    { path: '/work/:work_slug', name: 'work', component: WorkShow },
    { path: '/community', name: 'community', component: Community },
    { path: '/community/:community_slug', name: 'community', component: CommunityShow },
    { path: '/connect', name: 'connect', component: Connect },
  ]
export default {
  data: function () {
    return {
      screenW: window.outerWidth,
      message: "Hello Vue!",
      route: null,
      home: false,
      routes: [
        { path: '/', name: 'home', component: Home },
        { path: '/about', name: 'about', component: About },
      ]
    }
  },
  components: {
    Home, About
  },
  created() {
    // console.log('width', this.screenW)
    window.addEventListener("resize", this.resize);
    console.log({window})
    this.$router.addRoutes(routes)
    // this.$router.addRoute(routes)
    this.route = this.$route.name
    console.log('this route',this.route)
    this.home = this.route == 'home'
    // NavEventBus.$on('changeRoute', (route) => this.changeRoute(route) )
  },
  mounted() {
    NavEventBus.$on('changeRoute', (route) => this.changeRoute(route) )
  },
  // destroyed() {
  //   window.removeEventListener("resize", this.resize);
  // },
  methods: {
    resize() {
      // console.log('width', window.outerWidth)
      this.screenW = window.outerWidth
    },
    changeRoute(route) {
      // console.log('change route to',route)
      this.route = route
      this.home = route == 'home'
    }
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}


</style>
